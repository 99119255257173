// 中金浩简介
<template>
  <div class="company-profile">
    <div class="justify-around profile-title">
      <p>与全国合作金融机构<span>100</span>余家</p>
      <p>每年服务于知识产权融资额度超过<span>100</span>亿元</p>
    </div>
    <Info class="profile-info back-border" />
  </div>
</template>

<script>
import Info from '@/components/Info'
export default {
  name: 'CompanyProfile',
  components: {
    Info
  },
};
</script>

<style scoped lang="less">
.profile-title {
  p {
    font-size: 21px;
    font-weight: bold;
    color: #333333;
    span {
      font-size: 49px;
      color: #d73a2d;
    }
  }
}
.profile-info {
  margin-top: 36px;
  padding: 50px;
  /deep/ p {
    line-height: 33px;
    span {
      font-size: 18px !important;
    }
  }
}
</style>
